import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import PersonToContact from '../components/PersonToContact';

const Service = ({ data, location }) => {
  const { title, image, demo, author } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const { previous, next } = data;

  const contactTitle =
    demo === true
      ? 'Vereinbaren Sie eine unverbindliche Beratung und Produktdemo!'
      : 'Ihr Ansprechpartner bei Fragen zu unseren Dienstleistungen und für Projektanfragen';

  return (
    <Layout bodyClass="page-services-single">
      <SEO
        title={title}
        description={data.markdownRemark.excerpt}
        image={image}
        url={location.href}
      />
      <div className="container pt-6 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-12">
            <div className="service service-single">
              <h1 className="title">{title}</h1>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-6">
        <PersonToContact authorId={author} title={contactTitle} />
      </div>
      <div className="container pb-6">
        <div className="col-12 col-md-8">
          <nav>
            <ul className="prevnext">
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    {'← '}
                    {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title}
                    {' →'}
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $previousServiceId: String, $nextServiceId: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        demo
        author
      }
      fields {
        slug
      }
      html
      excerpt
    }
    previous: markdownRemark(id: { eq: $previousServiceId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextServiceId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

export default Service;
